<template>
 
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      url: baseEnv,
    };
  },

  methods: {
  
    home() {
      this.$router.push("/");
    },
  },
};
</script>

<style scope>
.g-1fr ul li {
  color: #fff;
  font-size: 15px;
  padding-bottom: 5px;
  cursor: pointer;
}
.logo {
  width: 65px;
  height: 65px;
  cursor: pointer;
}
</style>